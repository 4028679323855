<template>
  <div>
    <div class="select">
      <img class="china74" src="https://www.ytsyz.com.cn/d/file/p/2023/09-30/cadd7575f4c3a619dce48408138d2db1.jpg" alt="">
      <img class="china74" src="http://www.icesnow.com.cn/skin/icesnow2022/images/guoqing-73.jpg" alt="">
      <!--<input type="text" v-model="inputText" placeholder="输入案由、关键词、法院、当事人、律师...">
      <button @click="selectFn()">搜索</button>-->
    </div>
    <div class="mainContent">
      <div class="jianJie">
        <div class="module-box">
          <div class="box-tittle">
            <h2>关于我们</h2>
          </div>
          <div class="progress-wrap">
            <p>
              成都市金牛区法谷民商事线上调解中心（以下简称为法谷民商事调解中心或调解中心）于 2023 年 4 月成立，是四川省首家具有线上调解资格的调解中心，是一家专业化、职业化、市场化的民商事调解机构。
            </p>
            <p>
              法谷民商事调解中心业务主管单位为成都市金牛区司法局，业务指导单位为成都市金牛区司法局。
            </p>
            <p>
              法谷民商事调解中心调解范围：民商事纠纷调解；民商事法律行为见证；民商事法律咨询、法治宣传；接受国家机关、企事业单位邀请或者委托对民商事纠纷进行调解，调解中心采取线上与线下相结合的方式提供服务。
            </p>
            <p>
              法谷民商事调解中心具有社会公益性，按照低价有偿原则为当事人提供民商事纠纷调解服务。法谷民商事调解中心的调解员均是从退休法官、执业律师、仲裁员、劳动争议调解员中推荐遴选聘任。调解员均具有丰富的法律从业经验和融法、理、情于无形化解争议的调解智慧。
            </p>
            <p>
              民商事调解具有当事人全过程参与、程序灵活、安全保密、结果协商可控等独特优势，充分体现当事人意思自治。当事人争议经法谷民商事调解中心调解达成的调解协议，对双方当事人具有约束力，调解协议经人民法院司法确认，具有强制执行的效力。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      inputText: ''
    }
  },
  methods: {
    selectFn() {

    }
  }
}
</script>
<style scoped>
.mainContent {
  position: relative;
  width: 1200px;
  vertical-align: middle;
  display: inline-block;
}

.select {
  height: 280px;
  background: #ed1c24;
}

input {
  width: 1050px;
  height: 66px;
  border-radius: 5px;
  margin: 25px auto;
  border: 0px solid;
  padding-left: 5px;
  font-size: 18px;
}

button {
  background: black;
  color: white;
  width: 150px;
  height: 66px;
  border-radius: 4px;
  margin-left: 5px;
  font-size: 24px;
  border: 0px solid;
  padding-bottom: 9px;
  padding-left: 24px;
  letter-spacing: 22px;
}

.jianJie {
  background: white;
  padding: 5px 20px;
}

.module-box {
  margin-top: 20px;
  border: 1px solid #e5e5e5;
  border-top: 2px solid #e4393c;
  border-radius: 5px;
}

.china74 {
  width: 1200px;
  margin-bottom: 10px;
}

.box-tittle {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #e5e5e5;
  padding-left: 180px;
}

h2 {
  float: left;
  margin-left: -160px;
  font-size: 20px;
  color: #e4393c;
  font-weight: 700;
}

.progress-wrap {
  padding: 25px 0;
  text-align: left;
  text-indent: 2em;
  line-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
}
</style>
