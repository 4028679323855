<template>
  <div class="header">
    <div class="header-nav">
      <img src="../assets/logo.png" class="bag" />
      <div class="tittle">
        <p style="font-size: 30px; font-weight: 700; letter-spacing: 3px;">成都市金牛区法谷民商事线上调解中心</p>
        <p style="font-size: 21px;">Chengdu Jinniu Financial Dispute Mediation Center</p>
      </div>
      <div class="layout">
        <ul>
          <li v-for="(tab, index) in tabs" :key="index" :class="{ on: currentIndex === index }">
            <a href="#" @click="changeTab(index)">{{ tab }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tabs'],
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    changeTab(index) {
      this.currentIndex = index
      this.$emit('tab-change', index)
    }
  }
}
</script>
<style scoped>
.header {
  background: #ed1c24;
  height: 126px;
}

.header-nav {
  width: 100%;
  margin: 0 auto;
}

.tittle {
  color: white;
  float: left;
  margin-top: 30px;
}

.bag {
  width: 126px;
  float: left;
  margin-top: 8px;
}

.layout {
  margin: 0;
  width: 100%;
  vertical-align: middle;
  float: right;
  margin-top: -120px;
}

ul {
  float: right;
  padding-top: 70px;
}

li {
  float: left;
  height: 40px;
  line-height: 40px;
}

.on {
  background: #fff;
  border-radius: 3px;
}

.on a {
  color: #ed1c24;
  border-right: 0;
}

a {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 0 20px;
  font-size: 16px;
  color: #fff;
  border-right: 1px solid #e4393c;
  width: 121px;
  text-align: center;
}
</style>
